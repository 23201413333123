import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import VideoItem from "../VideoItem";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { Link } from "gatsby";
import "./index.scss";
import { BLOCKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import WaModeController from "../../../../controllers/webinars/waModeController";
import ScrollLink from "../../ScrollLink";

const options = {
  renderText: (text) =>
    text
      .split("\n")
      .flatMap((text, i) => [
        i > 0 && <br key={i} />,
        <Fragment key={i + "a"}>{text}</Fragment>,
      ]),
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <p className="UniversityInfo__description">{children}</p>
    ),
  },
};
const waModeController =
  typeof window !== "undefined" && new WaModeController();

const UniversityInfo = ({
  title,
  description,
  img,
  priceOne,
  priceTwo,
  textLink,
  pathLink,
  urlYoutube,
  updatePlayerState,
  offerRef,
  isWaMode,
  heightPage,
  handleRouteChange,
  slug,
}) => {
  const isMobile = !!useBreakpoint().mobMax;
  const [isShow, setIsShow] = useState(isMobile);
  const isMaxMobile = useBreakpoint().mdMax;

  useEffect(() => {
    if (typeof window !== "undefined") {
      const urlParams = new URLSearchParams(window.location.search);
      if ((urlParams.get("option") === "scroll" || isWaMode) && isMaxMobile) {
        offerRef?.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      } else if (isWaMode) {
        try {
          window.parent.scrollTo({ top: 0 });
        } catch (err) {
          window.scrollTo({ top: 0 });
        }
      }
    }
  }, []);

  useEffect(() => {
    setIsShow(isMobile);
  }, [isMobile]);

  return (
    <div className="UniversityInfo">
      <div className="UniversityInfo__title">{title}</div>
      <VideoItem
        adClassName="UniversityInfo__image"
        imageData={img}
        updatePlayerState={updatePlayerState}
        urlYoutube={urlYoutube}
        isWaMode={isWaMode}
        heightPage={heightPage}
      />
      {isShow ? (
        <div
          className="UniversityInfo__showMore"
          onClick={() => setIsShow(false)}
        >
          Show more
        </div>
      ) : (
        <div className="UniversityInfo__wrapDescription">
          {renderRichText(description, options)}
        </div>
      )}
      <div className="UniversityInfo__controller">
        <div className="UniversityInfo__price">
          <p>{`$${priceOne}`}</p>
          <p>{`$${priceTwo}`}</p>
        </div>
        <div className="UniversityInfo__blockButtons">
          <button
            className="UniversityInfo__whiteBtn"
            onClick={() => {
              isWaMode
                ? waModeController.unblockPaidContent(urlYoutube)
                : updatePlayerState("urlYoutubeModal", urlYoutube);
            }}
          >
            Watch preview
          </button>
          {offerRef ? (
            <ScrollLink
              refParameter={offerRef}
              classes="UniversityInfo__greenBtn"
            >
              {textLink}
            </ScrollLink>
          ) : handleRouteChange ? (
            <button
              className="UniversityInfo__greenBtn"
              onClick={() => handleRouteChange(slug)}
            >
              {textLink}
            </button>
          ) : (
            <Link
              to={
                isWaMode
                  ? `${pathLink + (pathLink.includes("?") ? "&" : "?")}mode=wa`
                  : pathLink
              }
              className="UniversityInfo__greenBtn"
            >
              {textLink}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

UniversityInfo.prototype = {
  img: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  isWaMode: PropTypes.bool.isRequired,
  priceOne: PropTypes.string.isRequired,
  priceTwo: PropTypes.string.isRequired,
  textLink: PropTypes.string.isRequired,
  pathLink: PropTypes.string.isRequired,
  offerRef: PropTypes.object,
  urlYoutube: PropTypes.string.isRequired,
  description: PropTypes.object.isRequired,
  updatePlayerState: PropTypes.func.isRequired,
  heightPage: PropTypes.number,
  handleRouteChange: PropTypes.func,
  slug: PropTypes.string.isRequired,
};

export default UniversityInfo;
